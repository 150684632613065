/* eslint-disable */

import $ from 'jquery';
import 'imagesloaded/imagesloaded'
import 'isotope-layout/dist/isotope.pkgd';

export default function () {
  const $grid = $('.grid-isotope');
  const $gridRealisations = $('.grid-isotope-realisations');

  $gridRealisations.imagesLoaded( function() {
    // init Masonry after all images have loaded
    $gridRealisations.isotope({
      itemSelector: '.grid-item',
    });
  });

  $grid.imagesLoaded( function() {
    // init Masonry after all images have loaded
    $grid.isotope({
      itemSelector: '.grid-item',
      getSortData: {
        weight: '[data-mobile-weight] parseInt',
      },
      sortBy: window.innerWidth < 1024 ? 'weight': 'original-order',
    });

    window.addEventListener('resize', () => {
      if (window.innerWidth < 1024) {
        $grid.isotope('reloadItems').isotope({ sortBy: 'weight' });
      } else {
        $grid.isotope('reloadItems').isotope({ sortBy: 'original-order' });
      }
    });
  });


  $('.filter-button-group').on('click', 'button', function filtering(e) {
    e.preventDefault();
    const filterValue = $(this).attr('data-filter');
    $grid.isotope({ filter: filterValue });
  });
}
