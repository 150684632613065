/* eslint-disable */
import $ from 'jquery';
import { matchMobile } from '../../constants/defaults';
import 'waypoints/lib/jquery.waypoints';


export default function () {

}

function footerWaypoint() {
  var UP = 'up',
    DOWN = 'down';
  var footerHeight = $('.page-footer').innerHeight();
  var tagline = $('.tagline-footer').outerHeight();
  var heightCTA = footerHeight - tagline;


  $('.page-footer').waypoint(function(direction) {

    if ($(window).width() < 812) {
      if (direction == DOWN) {
        $('.scrollHeader').hide();
        $('.scrollFooter').show();
        $('.scrollFooter').css('bottom', heightCTA + 200 + 'px');


      } else {
        $('.scrollHeader').show();
        $('.scrollFooter').hide();
      }
    }else{
      if (direction == DOWN) {
        $('.scrollHeader').hide();
        $('.scrollFooter').show();
        $('.scrollFooter').css('bottom', heightCTA + 'px');


      } else {
        $('.scrollHeader').show();
        $('.scrollFooter').hide();
      }
    }

  },{ offset: '100%' });


}

footerWaypoint();

$(window).resize(function(){
  footerWaypoint();
});

setTimeout(function(){
  footerWaypoint();
}, 100);



