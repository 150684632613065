/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
// Docs: https://docs.esign.eu/books/development/page/front-end-template#bkmrk-basicgooglemap

import googleMapsStyles from "../../constants/googleMapsStyles";

export default class BasicGoogleMap {
  init(mapSettings = null) {
    const google = window.google || {};
    this.googleMaps = google.maps;
    this.baseUrl = window.baseUrl || "";

    const holder = document.getElementById("map");

    if (holder) {
      const title = holder.getAttribute("data-title");
      const lat = holder.getAttribute("data-lat");
      const long = holder.getAttribute("data-lng");

      const map = this.addMap(holder, lat, long);
      this.addMarker(map, lat, long, title);
    }
  }

  addMap(holder, latitude, longitude) {
    const zoom = 15;
    const disable = true;
    const scroll = false;
    const styledMap = new this.googleMaps.StyledMapType(googleMapsStyles, {
      name: "Styled Map",
    });
    const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
    };
    const map = new this.googleMaps.Map(holder, mapOptions);

    map.mapTypes.set("map_style", styledMap);
    map.setMapTypeId("map_style");

    this.googleMaps.event.addDomListener(window, "resize", () => {
      map.setCenter(mapCenter);
    });

    return map;
  }

  addMarker(map, latitude, longitude, title) {
    const myLatlng = new this.googleMaps.LatLng(latitude, longitude);

    const markerIcon = {
      url: window.markerImg,
      size: new this.googleMaps.Size(100, 200),
      origin: new this.googleMaps.Point(0, 0),
      anchor: new this.googleMaps.Point(25, 64),
      scaledSize: new this.googleMaps.Size(22, 45),
    };

    const marker = new this.googleMaps.Marker({
      position: myLatlng,
      map,
      icon: markerIcon,
    });

    return marker;
  }
}
