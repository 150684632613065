import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
import fancybox from './components/layout/fancybox';
import isotope from './components/layout/isotope';
import waypoint from './components/layout/waypoint';
// import gaTracking from './components/tracking/gatracking';

import Contact from './pages/Contact';
import Project from './pages/Project';
import Home from './pages/Home';

import BasicGoogleMap from './components/maps/BasicGoogleMap';

require('./utils/nativeConsole');
require('isotope-layout/dist/isotope.pkgd');
require('waypoints/lib/jquery.waypoints');
require('imagesloaded/imagesloaded.pkgd');
require('@fancyapps/fancybox/dist/jquery.fancybox');
require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
responsiveVideos();
isotope();
waypoint();
fancybox();


// Forms
validation();

// Tracking
/* gaTracking(); */

const newsletterForm = document.getElementById('mc-embedded-subscribe-form');
if (newsletterForm) {
  newsletterForm.addEventListener('submit', (e) => {
    const emailInput = newsletterForm.querySelector('#mce-EMAIL');
    if (emailInput.classList.contains('mce_inline_error') || emailInput.getAttribute('aria-invalid') === true) {
      e.preventDefault();
    } else {
      window.dataLayer = window.dataLayer || []; window.dataLayer.push({
        event: 'Sign-up',
        formTitle: newsletterForm.dataset.title,
        formLabel: newsletterForm.dataset.label,
      });
    }
  });
}

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
  Project,
  Home,
};


const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    // Exceptional use of new
    // eslint-disable-next-line no-new
    new pages[pageClassName]();
  }
}

// Recaptcha submit handler for each form
const contactForm = new RecaptchaForm('#form-contact');
window.submitRecaptchaFormContact = () => {
  contactForm.submitCallback();
};

// Map
const map = new BasicGoogleMap();
map.init();
