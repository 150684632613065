/* eslint-disable */

import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox';

export default function () {
  $('[data-fancybox="video"]').fancybox({
    youtube : {
      controls : 0,
      showinfo : 0
    },
    vimeo : {
      color : 'f00'
    }
  });
}
