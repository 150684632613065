import $ from 'jquery';
import { matchMobile } from '../constants/defaults';
import '../components/layout/smoothscroll';

if (matchMobile) {
  $.each($('.residence-info-block'), (i, residence) => {
    const $residence = $(residence);
    const widthsColumns = {};
    $.each($residence.find('.residence__appartment__value'), (j, column) => {
      $(column).css('width', 'auto');
      const attr = $(column).data('name-attr');
      const w = $(column).width();
      if (widthsColumns[attr]) {
        if (widthsColumns[attr] < w) {
          widthsColumns[attr] = w;
        }
      } else {
        widthsColumns[attr] = w;
      }
    });

    Object.keys(widthsColumns).forEach((key) => {
      const maxWidth = widthsColumns[key];
      $residence.find(`.residence__appartment__value[data-name-attr= "${key}"]`).css('width', maxWidth);
    });
  });
  window.addEventListener('resize', () => {
    $.each($('.residence-info-block'), (i, residence) => {
      const $residence = $(residence);
      const widthsColumns = {};
      $.each($residence.find('.residence__appartment__value'), (j, column) => {
        $(column).css('width', 'auto');
        const attr = $(column).data('name-attr');
        const w = $(column).width();
        if (widthsColumns[attr]) {
          if (widthsColumns[attr] < w) {
            widthsColumns[attr] = w;
          }
        } else {
          widthsColumns[attr] = w;
        }
      });

      Object.keys(widthsColumns).forEach((key) => {
        const maxWidth = widthsColumns[key];
        $residence.find(`.residence__appartment__value[data-name-attr= "${key}"]`).css('width', maxWidth);
      });
    });
  });
}

$.each($('.residence-info-block'), (i, residence) => {
  const $residence = $(residence);
  const widthsColumns = {};
  $.each($residence.find('.residence__appartment__value'), (j, column) => {
    $(column).css('width', 'auto');
    const attr = $(column).data('name-attr');
    const w = $(column).width();
    if (widthsColumns[attr]) {
      if (widthsColumns[attr] < w) {
        widthsColumns[attr] = w;
      }
    } else {
      widthsColumns[attr] = w;
    }
  });

  Object.keys(widthsColumns).forEach((key) => {
    const maxWidth = widthsColumns[key];
    $residence.find(`.residence__appartment__value[data-name-attr= "${key}"]`).css('width', maxWidth);
  });
});
